exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-seguro-asistencia-medica-corporativo-js": () => import("./../../../src/pages/seguro-asistencia-medica-corporativo.js" /* webpackChunkName: "component---src-pages-seguro-asistencia-medica-corporativo-js" */),
  "component---src-pages-seguro-hogar-js": () => import("./../../../src/pages/seguro-hogar.js" /* webpackChunkName: "component---src-pages-seguro-hogar-js" */),
  "component---src-pages-seguro-salud-js": () => import("./../../../src/pages/seguro-salud.js" /* webpackChunkName: "component---src-pages-seguro-salud-js" */),
  "component---src-pages-seguro-vehicular-js": () => import("./../../../src/pages/seguro-vehicular.js" /* webpackChunkName: "component---src-pages-seguro-vehicular-js" */),
  "component---src-pages-seguro-vida-ahorro-js": () => import("./../../../src/pages/seguro-vida-ahorro.js" /* webpackChunkName: "component---src-pages-seguro-vida-ahorro-js" */),
  "component---src-pages-seguro-vida-corporativo-js": () => import("./../../../src/pages/seguro-vida-corporativo.js" /* webpackChunkName: "component---src-pages-seguro-vida-corporativo-js" */),
  "component---src-pages-seguros-complementarios-js": () => import("./../../../src/pages/seguros-complementarios.js" /* webpackChunkName: "component---src-pages-seguros-complementarios-js" */),
  "component---src-pages-seguros-patrimoniales-js": () => import("./../../../src/pages/seguros-patrimoniales.js" /* webpackChunkName: "component---src-pages-seguros-patrimoniales-js" */)
}

